export const ADD_TO_QUIZ = 'ADD_TO_QUIZ'
export const APP_SETTINGS = 'APP_SETTINGS'
export const ASSET = 'ASSET'
export const ASBESTOS_CLEARANCE = 'ASBESTOS_CLEARANCE'
export const ASBESTOS_SAMPLE_EDIT = 'ASBESTOS_SAMPLE_EDIT'
export const ASBESTOS_SAMPLE_EDIT_COC = 'ASBESTOS_SAMPLE_EDIT_COC'
export const ASBESTOS_SAMPLE_DETAILS = 'ASBESTOS_SAMPLE_DETAILS'
export const ASBESTOS_SOIL_SUBSAMPLE_WEIGHTS = 'ASBESTOS_SOIL_SUBSAMPLE_WEIGHTS'
export const ASBESTOS_COC_EDIT = 'ASBESTOS_COC_EDIT'
export const ASBESTOS_SAMPLE_LOG = 'ASBESTOS_SAMPLE_LOG'
export const ASBESTOS_LOGGED_SAMPLES = 'ASBESTOS_LOGGED_SAMPLES'
export const ASBESTOS_LAB_MANAGER = 'ASBESTOS_LAB_MANAGER'
export const ASBESTOS_LAB_STATS = 'ASBESTOS_LAB_STATS'
export const COC_ISSUES = 'COC_ISSUES'
export const COC_LOG = 'COC_LOG'
export const COC_STATS = 'COC_STATS'
export const ASBESTOS_ACTIONS = 'ASBESTOS_ACTIONS'
export const ASBESTOS_ACTION_DETAILS = 'ASBESTOS_ACTION_DETAILS'
export const COMMENT = 'COMMENT'
export const CONFIRM_RESULT = 'CONFIRM_RESULT'
export const DOCUMENT = 'DOCUMENT'
export const DOWNLOAD_LAB_CERTIFICATE = 'DOWNLOAD_LAB_CERTIFICATE'
export const INCIDENT = 'INCIDENT'
export const METHOD = 'METHOD'
export const QC_ANALYSIS = 'QC_ANALYSIS'
export const QUESTION = 'QUESTION'
export const QUIZ = 'QUIZ'
export const NOTICES = 'NOTICES'
export const REPORT_ACTIONS = 'REPORT_ACTIONS'
export const SAMPLE = 'SAMPLE'
export const SITE = 'SITE'
export const SITE_JOB = 'SITE_JOB'
export const SITE_VISIT = 'SITE_VISIT'
export const SOIL_DETAILS = 'SOIL_DETAILS'
export const TRAINING = 'TRAINING'
export const TEMPLATE_ACM = 'TEMPLATE_ACM'
export const TEMPLATE_BUILDING_MATERIAL = 'TEMPLATE_BUILDING_MATERIAL'
export const UPDATE_CERTIFICATE_VERSION = 'UPDATE_CERTIFICATE_VERSION'
export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_METHOD_VERSION = 'UPDATE_METHOD_VERSION'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const USER_ATTR = 'USER_ATTR'
export const VEHICLE = 'VEHICLE'
export const WA_ANALYSIS = 'WA_ANALYSIS'
export const WHOS_READ = 'WHOS_READ'
export const VERIFY_ISSUES_ASBESTOS = 'VERIFY_ISSUES_ASBESTOS'
export const WFM_TIME = 'WFM_TIME'
