// ACTION TYPES are saved as constants to avoid errors with typos and make debugging simpler.

export const GET_FIRESTORE_COLLECTION = 'GET_FIRESTORE_COLLECTION'

// Local
export const ADD_DOCUMENT = 'ADD_DOCUMENT'
export const AUTH_USER = 'AUTH_USER'
export const AUTHORISE_WFM = 'AUTHORISE_WFM'
export const CAT_CHANGE = 'CAT_CHANGE'
export const EDIT_USER = 'EDIT_USER'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_EDIT_STAFF = 'GET_EDIT_STAFF'
export const CLEAR_EDIT_STAFF = 'CLEAR_EDIT_STAFF'
export const GET_ASSETS = 'GET_ASSETS'
export const GET_LOGS = 'GET_LOGS'
export const CLEAR_LOG = 'CLEAR_LOG'
export const GET_HELP = 'GET_HELP'
export const GET_INCIDENTS = 'GET_INCIDENTS'
export const GET_ME = 'GET_ME'
export const GET_METHODLOG = 'GET_METHODLOG'
export const GET_METHODS = 'GET_METHODS'
export const GET_NOTICES = 'GET_NOTICES'
export const GET_NOTICE_READS = 'GET_NOTICE_READS'
export const GET_QUESTIONS = 'GET_QUESTIONS'
export const GET_QUIZZES = 'GET_QUIZZES'
export const GET_QUIZLOG = 'GET_QUIZLOG'
export const GET_READINGLOG = 'GET_READINGLOG'
export const GET_STAFF = 'GET_STAFF'
export const GET_TOOLS = 'GET_TOOLS'
export const GET_TRAININGS = 'GET_TRAININGS'
export const GET_UPDATES = 'GET_UPDATES'
export const GET_USER = 'GET_USER'
export const GET_VEHICLES = 'GET_VEHICLES'
export const RESET_LOCAL = 'RESET_LOCAL'
export const SEARCH_CHANGE = 'SEARCH_CHANGE'
export const SET_STEPPER = 'SET_STEPPER'
export const UPDATE_STAFF = 'UPDATE_STAFF'
export const GRAB_JOB_DATA = 'GRAB_JOB_DATA'
export const GRAB_LAB_DATA = 'GRAB_LAB_DATA'

// Modal
export const EDIT_MODAL = 'EDIT_MODAL'
export const EDIT_MODAL_DOC = 'EDIT_MODAL_DOC'
export const EDIT_MODAL_DOC_STEPS = 'EDIT_MODAL_DOC_STEPS'
export const EDIT_MODAL_SAMPLE = 'EDIT_MODAL_SAMPLE'
export const EDIT_MODAL_DOC_COMMENT = 'EDIT_MODAL_DOC_COMMENT'
export const EDIT_MODAL_DOC_SAMPLES = 'EDIT_MODAL_DOC_SAMPLES'
export const EDIT_MODAL_GLOSSARY = 'EDIT_MODAL_GLOSSARY'
export const HIDE_MODAL = 'HIDE_MODAL'
export const HIDE_MODAL_SECONDARY = 'HIDE_MODAL_SECONDARY'
export const SET_MODAL_ERROR = 'SET_MODAL_ERROR'
export const SHOW_MODAL = 'SHOW_MODAL'
export const SHOW_MODAL_SECONDARY = 'SHOW_MODAL_SECONDARY'

export const ADD_TAG = 'ADD_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const RESET_MODAL = 'RESET_MODAL'
export const RESET_MODAL_SECONDARY = 'RESET_MODAL_SECONDARY'

// Asbestos Lab
export const DELETE_COC = 'DELETE_COC'
export const GET_ASBESTOS_ANALYSIS = 'GET_ASBESTOS_ANALYSIS'
export const GET_AIR_ANALYSTS = 'GET_AIR_ANALYSTS'
export const GET_ASBESTOS_SAMPLES = 'GET_ASBESTOS_SAMPLES'
export const GET_BULK_ANALYSTS = 'GET_BULK_ANALYSTS'
export const GET_ASBESTOS_MICROSCOPE_CALIBRATIONS = 'GET_ASBESTOS_MICROSCOPE_CALIBRATIONS'
export const GET_COCS = 'GET_COCS'
export const GET_SAMPLES = 'GET_SAMPLES'
export const GET_SAMPLE_LOG = 'GET_SAMPLE_LOG'
export const GET_ASBESTOS_SAMPLE_ISSUE_LOGS = 'GET_ASBESTOS_SAMPLE_ISSUE_LOGS'
export const GET_ASBESTOS_ANALYSIS_LOGS = 'GET_ASBESTOS_ANALYSIS_LOGS'
export const GET_ASBESTOS_CHECK_LOGS = 'GET_ASBESTOS_CHECK_LOGS'
export const SET_ANALYSIS_MODE = 'SET_ANALYSIS_MODE'
export const SET_ANALYST = 'SET_ANALYST'
export const SET_ANALYSIS_SESSION_ID = 'SET_ANALYSIS_SESSION_ID'
export const SET_VIEW_SAMPLE_DETAIL = 'SET_VIEW_SAMPLE_DETAIL'
export const RESET_ASBESTOS_LAB = 'RESET_ASBESTOS_LAB'

// Jobs
export const GET_CURRENT_JOB_STATE = 'GET_CURRENT_JOB_STATE'
export const GET_GEOCODES = 'GET_GEOCODES'
export const ADD_TO_GEOCODES = 'ADD_TO_GEOCODES'
export const GET_SITE = 'GET_SITE'
export const GET_SITES = 'GET_SITES'
export const GET_WFM_JOBS = 'GET_WFM_JOBS'
export const GET_WFM_JOB = 'GET_WFM_JOB'
export const GET_WFM_LEADS = 'GET_WFM_LEADS'
export const GET_WFM_CLIENTS = 'GET_WFM_CLIENTS'
export const GET_WFM_CONTACT = 'GET_WFM_CONTACT'
export const SAVE_WFM_ITEMS = 'SAVE_WFM_ITEMS'
export const SAVE_WFM_STATS = 'SAVE_WFM_STATS'
export const GET_JOB_STATS = 'GET_JOB_STATS'
export const GET_JOB_LIST = 'GET_JOB_LIST'
export const GET_SITE_JOB = 'GET_SITE_JOB'
export const GET_SITE_JOBS = 'GET_SITE_JOBS'
export const GET_SITE_ACM = 'GET_SITE_ACM'
export const GET_SITE_COCS = 'GET_SITE_COCS'
export const ADD_TO_JOB_LIST = 'ADD_TO_JOB_LIST'
export const CLEAR_WFM_JOB = 'CLEAR_WFM_JOB'
export const RESET_JOBS = 'RESET_JOBS'
export const SET_LAST_TIME_SAVED = 'SET_LAST_TIME_SAVED'

// Display
export const APP_HAS_LOADED = 'APP_HAS_LOADED'
export const ASBESTOS_LAB_EXPANDED = 'ASBESTOS_LAB_EXPANDED'
export const ASBESTOS_SAMPLE_DISPLAY_MODE = 'ASBESTOS_SAMPLE_DISPLAY_MODE'
export const RESET_DISPLAY = 'RESET_DISPLAY'
export const TAB_STAFF = 'TAB_STAFF'
export const TAB_MY_DETAILS = 'TAB_MY_DETAILS'
export const TOGGLE_DO_NOT_RENDER = 'TOGGLE_DO_NOT_RENDER'
export const FILTER_STAFF = 'FILTER_STAFF'
export const FILTER_MAP = 'FILTER_MAP'
export const FILTER_MAP_RESET = 'FILTER_MAP_RESET'

// Const
export const INIT_CONSTANTS = 'INIT_CONSTANTS'

// Footprints
export const MARK_FOOTPRINT = 'MARK_FOOTPRINT'
export const MARK_TIME = 'MARK_TIME'
